import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import Reason from './Reason';
import I18n from '../base/i18n';

const Reasons = ({
  answer,
  reasonableId,
  reasonableType,
  userCanModifyReason,
  reasons: propReasons
}) => {
  I18n.locale = $('body').data('locale');

  const [removed, setRemoved] = useState(0);
  const [reasons, setReasons] = useState(propReasons);

  useEffect(() => {
    setReasons(propReasons);
  }, [propReasons]);

  const remove = () => setRemoved(removed + 1);

  const addReason = () => setReasons([...reasons, null]);

  const disagreeing =
    answer &&
    answer.audit_result &&
    answer.answer &&
    answer.audit_result !== answer.answer;

  const noReasons = (disagreeing && (
    <p className="assessmentAnswer-auditReasonHint assessmentAnswer-auditReasonHintAlert">
      {I18n.t('assessments.questions.details.no_audit_reason_alert')}
    </p>
  )) || (
    <p className="assessmentAnswer-auditReasonHint">
      {I18n.t('assessments.questions.details.no_audit_reason')}
    </p>
  );

  if (!reasons) return <></>;

  const headingI18nKey = () => {
    switch(reasonableType) {
      case 'LopTask':
        return 'lop_tasks.release_reason';
      case 'ExternalNonConformityTask':
        return 'external_non_conformity_tasks.reason_heading'
      default:
        return 'activerecord.attributes.assessment_answer.audit_reason'
    }
  };

  return (
    <>
      <h5>
        <i className="far fa-lightbulb fa-xs me-2" />
        {I18n.t(headingI18nKey())}
        {userCanModifyReason && (
          <button
            type="button"
            onClick={addReason}
            data-tippy-placement="right"
            className="btn btn-secondary btn-xs ms-2"
            data-tippy-content={I18n.t(
              'assessments.questions.details.add_audit_reason'
            )}
          >
            <i className="fas fa-plus" />
          </button>
        )}
      </h5>

      <div className="ms-4">
        {reasons.length - removed === 0 && noReasons}

        {reasons.map((reason, i) => (
          <Reason
            key={`new${i}`}
            reason={reason}
            remove={remove}
            reasonableId={reasonableId}
            reasonableType={reasonableType}
            userCanModifyReason={userCanModifyReason}
            lastReasonOfReasonable={i === reasons.length - 1}
          />
        ))}
      </div>
    </>
  );
};

Reasons.defaultProps = {
  answer: null
};

Reasons.propTypes = {
  answer: PropTypes.instanceOf(Object),
  reasonableId: PropTypes.string.isRequired,
  reasonableType: PropTypes.string.isRequired,
  userCanModifyReason: PropTypes.bool.isRequired,
  reasons: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Reasons;
